<script setup lang="ts">
  import { PropType } from 'vue'
  import type { CloseBtn } from '~~/types/closeBtn'

  const props = defineProps({
    setting: Object as PropType<CloseBtn>
  })
  const setting = props.setting;
</script>
<template>
  <button
    class="
      border-2
      flex
      items-center
      justify-center
      rounded-full
    "
    :class="
      {
        'bg-gray-400' : setting?.bgColor === 'gray',
        'bg-transparent' : setting?.bgColor === 'transparent',
        'border-gray-400' : setting?.borderColor === 'gray',
        'border-white' : setting?.borderColor === 'white',
        'p-1.5 h-1 w-1' : setting?.btnSize === 'tiny',
        'p-2 h-1 w-1' : setting?.btnSize === 'small',
        'p-2 h-8 w-8' : setting?.btnSize === 'medium',
        'p-2 h-15 w-15' : setting?.btnSize === 'large',
      }
    "
  >
    <span
      class="
        material-icons
      "
      :class="
        {
          'text-white' : setting?.iconColor === 'white',
          'sm-12' : setting?.btnSize === 'tiny',
          'sm-8' : setting?.btnSize === 'small',
          'md-18' : setting?.btnSize === 'medium',
          'md-24' : setting?.btnSize === 'large',
        }
      "
    >
      close
    </span>
  </button>
</template>
